exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-szkolenie-vat-zagraniczny-w-branzy-ssc-bpo-tsx": () => import("./../../../src/pages/szkolenie-vat-zagraniczny-w-branzy-ssc-bpo.tsx" /* webpackChunkName: "component---src-pages-szkolenie-vat-zagraniczny-w-branzy-ssc-bpo-tsx" */),
  "component---src-pages-team-agnieszka-tsx": () => import("./../../../src/pages/team/agnieszka.tsx" /* webpackChunkName: "component---src-pages-team-agnieszka-tsx" */),
  "component---src-pages-team-aneta-tsx": () => import("./../../../src/pages/team/aneta.tsx" /* webpackChunkName: "component---src-pages-team-aneta-tsx" */),
  "component---src-pages-team-tomasz-tsx": () => import("./../../../src/pages/team/tomasz.tsx" /* webpackChunkName: "component---src-pages-team-tomasz-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

